/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // var offset = parseInt($('#postContainer').attr('data-start-number'));

        var count = parseInt($('#infodiv').attr('data-end-number'));
        var minOffset = parseInt($('#postContainer').attr('data-start-number'));
        var deafultOffset = parseInt($('#postContainer').attr('data-end-number'));
        var offset = $( "#postContainer .post-item" ).length;
        var curFilter ='';
        var textButton = $('#load-more').text();

        var postItemWidth = $('.post-item').width();

             // Video Seitenverhältnis anpassen
        function imageRatio(){
          var videoOrgHeight = 480;
          var videoOrgWidth = 360;
          postItemWidth = $('.post-item').width();
          // postNEWItemWidth = Math.ceil(postItemWidth);
          // $('.post-item').width(postNEWItemWidth-1);

          $('.post-item').height(Math.ceil(videoOrgHeight/videoOrgWidth * postItemWidth));
        }

        function checkLoadMoreVisibility(){
            // console.log('minOffset:' + minOffset);
            // console.log('count:' + count);
            // console.log('offset:' + offset);

             count = parseInt($('#infodiv').attr('data-end-number'));
            if(offset < count && minOffset <= count ) {


              $('#load-more').show();

              }else{
                  $('#load-more').hide();
              }


        }


        $('.filter-navigation span').on("click", function() {
              curFilter = $(this).data('filter');
              count = parseInt($('#infodiv').attr('data-end-number'));
              var link = $('#postContainer').attr('data-link')+'?curterm='+curFilter;
              offset = $( "#postContainer .post-item" ).length;
              $.get(link, function(data){
                $('#postContainer').html(
                  $(data).find('#postContainer').html()
                );
                checkLoadMoreVisibility();

              });

        });


        function magnificPopup(){

          // if($(window).width() > 640){

            $('.entry-images, .entry-content p, .lightbox').magnificPopup({
              delegate: "a[href$='.jpg']",
              type: 'image',
              closeMarkup: '<button type="button" class="mfp-close"></button>',
              closeBtnInside: false,
              showCloseBtn: false,
              tLoading: 'Loading image #%curr%...',
              gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
              },
              image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
              }
            });

            //  $('.entry-content').magnificPopup({
            //   delegate: 'a',
            //   type: 'image',
            //   closeMarkup: '<button type="button" class="mfp-close"></button>',
            //   closeBtnInside: false,
            //   showCloseBtn: false,
            //   tLoading: 'Loading image #%curr%...',
            //   gallery: {
            //     enabled: true,
            //     navigateByImgClick: true,
            //     preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            //   },
            //   image: {
            //     tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            //   }
            // });

          // }

        }

        function loadPosts(){


          $('#load-more-wrapper').addClass('loading');
					var link = null;
          if(curFilter !== '') {
            link = $('#postContainer').attr('data-link')+'?curterm='+curFilter+'&offset='+offset;
          }else{
            link = $('#postContainer').attr('data-link')+'?offset='+offset;
          }

          $.get(link, function(data){

            $('#load-more-wrapper').removeClass('loading');

            $('#postContainer').html(
              $(data).find('#postContainer').html());
              checkLoadMoreVisibility();
            });
        }

        $('#load-more').on('click', function(){
            offset = offset+24;
            loadPosts();

        });

        $('.icon.search').on('click', function(){
            $('.search-container').addClass('active');
            $(this).addClass('active');


        });



        // History Go Back Function
        function goBack(event){
          window.history.back();
        }

       // Close search Field / If search results page, then do GoBack

        $('.search-close').on('click', function(){

            if($(this).hasClass('goback')){
              goBack();
            }else{
              $('.search-container').removeClass('active');
              $('.icon.search').removeClass('active');
              $('.search-field').val('');
            }


        });

        // Expanding-textarea
        $('.expanding').expanding();



        // Slider for main page

        $('.bxslider').bxSlider({
              controls: false,
              pager: false,
              auto: true,
              autoStart: true,
              randomStart: true,
              infiniteLoop: true,
              preloadImages: 'all'
        });




        function soliloquyImageHeight() {
             var windowHeight = $(window).height();
          $('#introSlider, .bx-wrapper, .bx-viewport').height(windowHeight - 200);

        }


        function handleMobileNavigation() {
          $('.mobile-nav-wrapper').addClass('mobile opened');
          $('.search-icon-container').addClass('mobile active');
          $('.icon.search').addClass('mobile');
          $('.close-menu-button').addClass('active');
          $('.hamburger').removeClass('active');
          $('body').addClass('fixed');

        }

        function closeMobileNavigation() {

            $('.mobile-nav-wrapper').removeClass('mobile closed');
            $('.search-icon-container').removeClass('mobile active');
            $('.close-menu-button').removeClass('active');
            $('.icon.search').removeClass('mobile');

            $('.hamburger').addClass('active');
            $('body').removeClass('fixed');

        }


        $('.contact-form > div').on('click', function(){

            $(this).find('input, textarea').focus();
        });

        // function hideContactOutput() {
        //   $('.wpcf7-response-output.wpcf7-validation-errors').hide();
        // }


        //Click Handler for Posts overview, even after load
        $("#mygallery").delegate(".touch", 'click', function(){
          $('.hover-container').hide();
          $( this ).find('.hover-container').show();
        });


        $(".hamburger").on('click', handleMobileNavigation);
        $(".close-menu-button").on('click', closeMobileNavigation);
        $(".back-button").on('click', goBack);


        $('.close-cta-interferer').on('click', function(){
          $('#cta-interferer').removeClass('show');
          $('#cta-interferer').removeClass('not-clicked');
          setCookie('ctapop', true, 1);
        });

        $('.heart-cta').on('click', function(){
          $('#cta-interferer').addClass('show');
        });


        // COOKIES
        function setCookie(cname, cvalue, exdays) {
          var d = new Date();
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          var expires = "expires="+ d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
          var name = cname + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');
          for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }

        function checkCookie(cname) {
          var curCname = getCookie(cname);

          if (curCname != "") {
            $('#cta-interferer').removeClass('not-clicked');
          } else {
            $('#cta-interferer').addClass('not-clicked');
          }
        }

        $(window).scroll(function() {


            if($('#cta-interferer').length > 0) {
              if ($(document).scrollTop() > 100) {
                  $('#cta-interferer.not-clicked').addClass('show');
              }
              else {
                  $('#cta-interferer.not-clicked').removeClass('show');
              }

             if($(window).scrollTop() + $(window).height() == $(document).height()) {
                $('#cta-interferer').addClass('at-bottom');
             }
             else {
                $('#cta-interferer').removeClass('at-bottom');
             }

            }

        });

        $(window).on('resize', function() {
            soliloquyImageHeight();
            magnificPopup();
            imageRatio();

        });

        soliloquyImageHeight();
        checkLoadMoreVisibility();
        magnificPopup();
        imageRatio();

        checkCookie('ctapop');


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
